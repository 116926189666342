import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_ASSOCIATED_URL } from '../../connection.data';

@Injectable()
export class AssociatedService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_ASSOCIATED_URL;
    }

    getBySaleOrder(saleOrderId: string|number){
        return this.http.get(`${this.baseUrl}/sale-order/${saleOrderId}`);
    }
}